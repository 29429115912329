<template>
    <div class="perfectBox">
        <div class='perfect' v-if="showjobNumber">
            <div class="perfectTitle">完善工号</div>
            <div class="perfectNumber">
                <input type="text" v-model.trim="jobNumber" placeholder="请输入工号">
            </div>
            <div class="perfectBtn">
                <van-button type="info" round :disabled="!jobNumber" @click="editPerfectJobNumber">确定</van-button>
            </div>
        </div>

        <div class='perfect' v-if="!showjobNumber">
            <div class="perfectTitle">完善姓名</div>
            <div class="perfectNumber">
                <input type="text" v-model.trim="userName" placeholder="请输入姓名">
            </div>
            <div class="perfectBtn">
                <van-button type="info" round :disabled="!userName" @click="editPerfectName">确定</van-button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: '',
    components: {},
    props: {},
    data() {
        return {
            jobNumber: '',
            userName: '',
            showjobNumber: true,
            user: JSON.parse(localStorage.userInfo),
        }
    },
    computed: {},
    watch: {},
    created() { },
    mounted() { 
        console.log(this.$route)
        const { showjobNumber } = this.$route.query
        if (showjobNumber == 'false') {
            this.showjobNumber = false
        }
    },
    methods: {
        editPerfectJobNumber() {
            const { id } = this.user
            this.$axios.post("/user/updateUserJobNumber", {
                userId:id,
                jobNumber: this.jobNumber
            })
            .then(res => {
                if (res.code == "ok") {
                    this.$toast.success('操作成功');
                    let newUserInfo = {
                        ...this.user,
                        jobNumber: this.jobNumber
                    }
                    localStorage.setItem('userInfo', JSON.stringify(newUserInfo))
                    this.$router.go(-1);
                } else {
                    this.$toast.fail(res.msg);
                }
            }).catch(err => {
                this.$toast.fail(err);
            });
        },
        editPerfectName() {
            this.$axios.post("/user/userRename", {
                newName: this.userName
            })
            .then(res => {
                if (res.code == "ok") {
                    this.$toast.success('操作成功');
                    let newUserInfo = {
                        ...this.user,
                        name: this.userName
                    }
                    localStorage.setItem('userInfo', JSON.stringify(newUserInfo))
                    this.$router.go(-1);
                } else {
                    this.$toast.fail(res.msg);
                }
            }).catch(err => {
                this.$toast.fail(err);
            });
        }
    },
}
</script>
<style scoped lang='less'>
.perfect {
    width: 100%;
    height: 100vh;
    background: #fff;

    .perfectTitle {
        font-size: 30px;
        padding-top: 20vh;
        text-align: center;
        color: #00a0e9;
    }

    .perfectNumber {
        width: 100%;
        display: flex;
        justify-content: center;

        input {
            width: 80%;
            height: 50px;
            border: 1px solid #ccc;
            border-radius: 5px;
            margin-top: 8vh;
            padding-left: 10px;
            font-size: 16px;
            border-radius: 50px;
            text-align: center;
        }
    }

.perfectBox {
    width: 100vw;
    height: 100vh;
}
    .perfectBtn {
        width: 80%;
        margin: 0 auto;

        .van-button {
            margin-top: 10vh;
            width: 100%;
            height: 50px;
            border-radius: 50px;
            background: #00a0e9;
            color: #fff;
        }
    }
}
</style>
